var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container online-info-page"},[_c('el-form',{ref:"queryForm",attrs:{"model":_vm.queryParams,"size":"mini","inline":true,"label-width":"75px"}},[_c('el-form-item',{attrs:{"label":"选择城市","prop":"cityRegion"}},[_c('el-select',{staticClass:"input-750",attrs:{"clearable":"","filterable":"","placeholder":""},model:{value:(_vm.queryParams.cityRegion),callback:function ($$v) {_vm.$set(_vm.queryParams, "cityRegion", $$v)},expression:"queryParams.cityRegion"}},_vm._l((_vm.cityList),function(item){return _c('el-option',{attrs:{"label":item.name,"value":item.key}})}),1)],1),_c('el-form-item',[_c('el-button',{attrs:{"icon":"el-icon-search","size":"mini"},on:{"click":_vm.handleQuery}},[_vm._v("搜索")]),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-plus","size":"mini"},on:{"click":_vm.handleAdd}},[_vm._v(" 新增 ")])],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.tableData},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"label":"城市","align":"center","prop":"cityName"}}),_c('el-table-column',{attrs:{"label":"区域","align":"center","prop":"regionName"}}),_c('el-table-column',{attrs:{"label":"社保补缴","align":"center","prop":"insurancePayBackMonth"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.insurancePayBackMonth === 0 ? '不允许补缴' : '允许补缴前' + row.insurancePayBackMonth + '个月')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"公积金补缴","align":"center","prop":"fundPayBackMonth"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.fundPayBackMonth === 0 ? '不允许补缴' : '允许补缴前' + row.fundPayBackMonth + '个月')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"社保差","align":"center","prop":"diffFlag"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.diffFlag === 1 ? '有社保差' : '无社保差')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"大额医疗缴纳类型","align":"center","prop":"largeMedicalPayType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.largeMedicalPayType === 1 ? '按月' : '按年')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"每月缴费截止日期","align":"center","prop":"payEndDate"}}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"180px","class-name":"small-padding fixed-width"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini","type":"text"},on:{"click":function($event){return _vm.handleUpdate(scope.row)}}},[_vm._v(" 编辑 ")]),_c('el-button',{attrs:{"size":"mini","type":"text"},on:{"click":function($event){return _vm.handleDelete(scope.row)}}},[_vm._v(" 删除 ")])]}}])})],1),_c('pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.total>0),expression:"total>0"}],attrs:{"total":_vm.total,"page-sizes":[16, 30, 50, 80, 100],"page":_vm.queryParams.page,"limit":_vm.queryParams.limit},on:{"update:page":function($event){return _vm.$set(_vm.queryParams, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.queryParams, "limit", $event)},"pagination":_vm.getList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }